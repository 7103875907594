
import { defineComponent } from "vue";

export type DataType = {
    spreadsheet?: any
 };
 
export default defineComponent({
    name: "SpreadSheet" ,
    data(): DataType  {  return { spreadsheet : undefined } } ,
    //this.spreadsheet.loadData({}) // load data  .change( (data : any) => { console.log(data); });
    props:{ xSpreadSheetOption :Object , xSpreadSheetData:Object},
    mounted :async function(this: { spreadsheet:any ,xSpreadSheetOption? :Object , xSpreadSheetData:Object }){
       
       console.warn("spreadsheet created");
       if(!this.spreadsheet){
       this.spreadsheet = 
        ( this.xSpreadSheetOption)?  (window as any).x_spreadsheet('#x-spreadsheet',this.xSpreadSheetOption)
       
       .loadData(this.xSpreadSheetData) :
        (window as any). x_spreadsheet( "#x-spreadsheet").loadData(  this.xSpreadSheetData );
       }
            
  },methods:{
    getSpreadSheet(
      this: { spreadsheet:any }
      ){
     
        return this.spreadsheet;
      } 
  }
});


